import { Constants } from './constants';
import { createBrowserScript } from './create-browser-script';
import { Domain } from './domains';
import { Logger } from './Logger';
import { resolveDomain } from './resolve-domain';
import { fetchLiveChatFrontendsVersions } from './resolve-version';
import type { LCVersions } from './types';

const getWidgetScriptUrlWithVersion = (ver: string) => {
    if (import.meta.env?.VITE_BUILD_ENV === 'local') {
        return `${Constants.LIVECHAT_BASE_URL}/widget.js`;
    }
    return `${Constants.LIVECHAT_BASE_URL}/widget-server/${ver}/widget.js`;
};

/**
 * This is the new entry point of the LiveChat application (FE).
 * Note that this script is IIFE (Immediately Invoked Function Expression).
 *
 * Needs to be deployed under 'https://livechat.ioinfobip.com/widget.js';
 */
(async function lcLoader(window) {
    Logger.log(
        'version:',
        import.meta.env?.VITE_LC_LOADER_VERSION,
        import.meta.env?.VITE_BUILD_TIME,
        import.meta.env?.VITE_BUILD_ON
    );
    try {
        window.LC_versions = window.LC_versions ?? {
            widget: { links: { scripts: [] }, version: '' },
            components: { links: { scripts: [] }, version: '' },
        };

        // 1. resolve domain
        const domain = await resolveDomain(resolveWidgetId());

        // 2. Fetch the latest versions of the LiveChat frontends from FDM
        await getLiveChatFrontendsVersions(domain);

        // 3. Load the widget script 🚀
        await createBrowserScript(window.LC_versions.widget.links.scripts?.[0] ?? '');
    } catch (error) {
        Logger.log('❗Fallback » loading from __latest__:', error);
        Logger.error(error);
        // 4. In case of an error, fallback to the latest version of the widget:
        await fallbackForLoadLatestVersion();
    }
})(window);

async function fallbackForLoadLatestVersion() {
    Logger.log('4. using latest version of the widget…');

    // Fallback to the latest version of the widget (under path `__latest__`),
    // example: https://livechat.infobip.com/widget-server/__latest__/widget.js
    await createBrowserScript(getWidgetScriptUrlWithVersion(Constants.LATEST)).catch(async () => {
        Logger.error('4. Failed to load the latest version of the widget!');
    });
}

export async function getLiveChatFrontendsVersions(domain: Domain): Promise<LCVersions> {
    try {
        const lcVersions = await fetchLiveChatFrontendsVersions(domain.apiDomain);

        // allow overriding loading LC by providing `window.LC_versions`, for local dev env only
        if (import.meta.env?.VITE_BUILD_ENV === 'local') {
            const { widget, components } = window.LC_versions ?? {};
            window.LC_versions = {
                widget: widget?.version === 'local' ? widget : lcVersions.widget,
                components: components?.version === 'local' ? components : lcVersions.components,
            };
        } else {
            window.LC_versions = lcVersions;
            createPreloadLinkElements(lcVersions);
        }

        Logger.log('2. window.LC_versions:', window.LC_versions);
        return lcVersions;
    } catch {
        return {
            widget: { links: { scripts: [] }, version: Constants.LATEST },
            components: { links: { scripts: [] }, version: Constants.LATEST },
        };
    }
}

/**
 * Resolves the widget ID from the `window.liveChat.q` array.
 * when there is a call: `liveChat('init', 'widgetId')`
 * then an array `window.liveChat.q` will contain array-like type IArguments with value: ['init', 'widgetId']
 * Function is exported for testing purposes - It has no effect on the production code.
 */
export function resolveWidgetId(): string | never {
    Logger.log('0. resolving widget ID from liveChat.q');
    const resolvedWidgetId = resolveWidgetIdFromInitApiCall() ?? resolveWidgetIdFromConfigApiCall();

    if (!resolvedWidgetId) {
        throw new Error(`0. Could not resolve widget ID!`);
    }

    Logger.log('0. ↳ resolved widget ID:', resolvedWidgetId);
    return resolvedWidgetId;
}

function resolveWidgetIdFromInitApiCall(): string | undefined {
    const lcApiCallInit = window.liveChat.q?.map((it) => Array.from(it)).find((it) => it[0] === 'init');

    if (!(Array.isArray(lcApiCallInit) && lcApiCallInit.length >= 2)) {
        return undefined;
    }

    Logger.log(`0. found 'init' API call:`, lcApiCallInit);

    const secondParamInit = lcApiCallInit[1];
    let resolvedWidgetId: string | undefined;

    if (typeof secondParamInit === 'string') {
        resolvedWidgetId = secondParamInit;
    }
    // undocumented feature: passing an object with widgetId property: liveChat('init', { widgetId: '…', domain: '…' })
    if (secondParamInit && typeof secondParamInit === 'object' && 'widgetId' in secondParamInit) {
        resolvedWidgetId = secondParamInit.widgetId;
    }
    return resolvedWidgetId;
}

function resolveWidgetIdFromConfigApiCall(): string | undefined {
    const lcApiCallConfig = window.liveChat.q?.map((it) => Array.from(it)).find((it) => it[0] === 'config');

    if (!(Array.isArray(lcApiCallConfig) && lcApiCallConfig.length >= 2)) {
        return undefined;
    }

    Logger.log(`0. found 'config' API call:`, lcApiCallConfig);

    const secondParamConfig = lcApiCallConfig[1];
    let resolvedWidgetId: string | undefined;

    // undocumented feature: liveChat('config', { widgetId: '…', domain: '…' })
    if (secondParamConfig && typeof secondParamConfig === 'object' && 'widgetId' in secondParamConfig) {
        resolvedWidgetId = secondParamConfig.widgetId;
    }
    return resolvedWidgetId;
}

/**
 *  Creates preload link elements for the widget and components scripts.
 *  > It tells browser about the resources that the page will need very soon,
 *    which you want to start loading early in the page lifecycle, before browsers' main rendering machinery kicks in.
 *    This ensures they are available earlier and are less likely to block the page's render, improving performance.
 *
 * - note: preload resources for widget-components causing warnings in the console:
 *   ```
 *   The resource from “https://livechat.infobip.com/widget-server/1.0.0/app.bundle.js” was preloaded using
 *   link preload but not used within a few seconds from the window's load event.
 *   ```
 *    - this is because the widget-components are loaded within the iframe.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/preload
 */
function createPreloadLinkElements({ widget }: LCVersions) {
    const links = (widget.links?.scripts ?? []).concat(widget.links?.styles ?? []);
    links
        .filter((src) => src.endsWith('.js') || src.endsWith('.css'))
        .forEach((src) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = src.endsWith('.js') ? 'script' : 'style';
            link.href = src;
            document.head.appendChild(link);
        });
}
