import { Constants, ResponseStatus } from './constants';
import { Domain, Domains } from './domains';
import { LivechatError } from './LiveChatError';
import { getDomainsByAppEnvironment } from './resolve-domain';
import { WidgetInfoResponse, LiveChatResponse, WidgetConfigPartial } from './types';

export async function resolveWidgetInfo(widgetId: string, domains: Domains): Promise<WidgetInfoResponse> {
    const widgetInfo =
        (await tryToFetchWidgetInfoFromDomains(domains.primary, widgetId)) ||
        (await tryToFetchWidgetInfoFromDomains(domains.secondary, widgetId));

    if (!widgetInfo) {
        throw new LivechatError(Constants.ERRORS.WIDGET_NOT_FOUND);
    }
    storeWidgetInfo(widgetInfo);
    return widgetInfo;
}

function storeWidgetInfo(widgetInfo: WidgetInfoResponse | null) {
    window.localStorage.setItem(Constants.STORAGE_KEYS.widgetInfoResponse, JSON.stringify(widgetInfo));
    window.localStorage.setItem(Constants.STORAGE_KEYS.widgetInfo_timestamp, JSON.stringify(Date.now()));
}

async function tryToFetchWidgetInfoFromDomains(
    domains: Domain[],
    widgetId: string
): Promise<WidgetInfoResponse | null> {
    const promises = domains.map((domain) => tryToFetchWidgetInfo(widgetId, domain));

    return getFirstPromiseWhichConditionMet(promises, (result): result is WidgetInfoResponse => result !== null).catch(
        () => null
    );
}

function resolveDomainByLocationIdOrDefault(locationId: number, defaultDomain: Domain): Domain {
    const { primary, secondary } = getDomainsByAppEnvironment();
    return [...primary, ...secondary].find((it) => it.locationId === locationId) || defaultDomain;
}

async function tryToFetchWidgetInfo(widgetId: string, requestDomain: Domain): Promise<WidgetInfoResponse | null> {
    const getFullDomainURL = (apiDomain: string) => `https://${apiDomain}/livechat`;
    const configRequestParams = { method: 'GET', credentials: 'omit' } as RequestInit;
    const isLiveChatResponse = ({ headers, status }: Response) => {
        return status === 200 && headers.get('content-type')?.includes(Constants.LIVECHAT_CONTENT_TYPE);
    };

    const response = await fetch(
        `${getFullDomainURL(requestDomain.apiDomain)}/api/widgets/${widgetId}/configuration`,
        configRequestParams
    );

    if (!isLiveChatResponse(response)) {
        return null;
    }

    const result: LiveChatResponse = await response.json();
    switch (result.status) {
        case ResponseStatus.SUCCESS: {
            const { enabled, config, customization } = result.payload;
            const domain = resolveDomainByLocationIdOrDefault(config.locationId, requestDomain);

            return {
                domain: domain.domain,
                urlPath: getFullDomainURL(domain.apiDomain),
                enabled,
                config: resolveReoccurringGreetingInConfig(config as WidgetConfigPartial),
                serverTime: response.headers.get('Date') || undefined,
                customization,
            } as WidgetInfoResponse;
        }
        case ResponseStatus.LIVE_CHAT_PRODUCT_NOT_AVAILABLE:
            throw new LivechatError(Constants.ERRORS.LIVE_CHAT_PRODUCT_NOT_AVAILABLE);
        case ResponseStatus.WIDGET_CONFIG_NOT_FOUND:
        default:
            return null;
    }
}

async function getFirstPromiseWhichConditionMet<T>(
    promises: Promise<T>[],
    condition: (result: T) => boolean
): Promise<T> {
    const wrappedPromises = promises.map(async (promise) => {
        const result = await promise;
        if (condition(result)) {
            return result;
        }
        throw new Error('Condition not met');
    });

    try {
        return await Promise.any(wrappedPromises);
    } catch (error) {
        if (error instanceof AggregateError) {
            throw new Error('No promise fulfilled the condition');
        }
        throw error;
    }
}

function resolveReoccurringGreetingInConfig(typedConfig: WidgetConfigPartial): WidgetConfigPartial {
    if ('SAME_AS_WELCOME' === typedConfig.reoccurringGreeting?.type) {
        typedConfig.reoccurringGreeting.greetingMsg = typedConfig.messaging.greetingMsg;
        typedConfig.reoccurringGreeting.greetingMsgTranslations = typedConfig.messaging.greetingMsgTranslations;
        typedConfig.reoccurringGreeting.greetingMsgImage = typedConfig.messaging.greetingMsgImage;
        typedConfig.reoccurringGreeting.quickReplies = typedConfig.messaging.quickReplies;
        typedConfig.reoccurringGreeting.ctaButtons = typedConfig.messaging.ctaButtons;
    }
    return typedConfig;
}
