import { Constants } from './constants';
import { Domains, IO, FR, EU1, NY2, MUNM, ID1, SA2, EU2, TR1, GB1, AE2, PK2, FR4, QA1, Domain } from './domains';
import { Logger } from './Logger';
import { resolveWidgetInfo } from './resolve-widget-info';

export function getDomainsByAppEnvironment(): Domains {
    const isProd = import.meta.env?.VITE_BUILD_ENV === 'production';
    return {
        primary: isProd ? [FR, EU1] : [IO],
        secondary: isProd ? [NY2, MUNM, ID1, SA2, EU2, TR1, GB1, AE2, PK2, FR4, QA1] : [],
    };
}

/**
 * uses the same logic as in the widget-server when calling {{MULTIPLE_DOMAINS}}/api/widgets/{widgetId}/configuration
 * @returns domain (FQDN) of the widget, for example, `livechat-iop1.ioinfobip.com`
 */
export async function resolveDomain(widgetId: string): Promise<Domain> {
    Logger.log('1. resolving domain…');

    const widgetInfo = await resolveWidgetInfo(widgetId, getDomainsByAppEnvironment());
    storeWidgetHosts(widgetInfo);

    const resolvedDomainInfo = findDomainByDomainName(widgetInfo.domain);
    Logger.log('1. ↳ resolved domain:', resolvedDomainInfo);

    window.LC_domain = resolvedDomainInfo;
    return resolvedDomainInfo;
}

function storeWidgetHosts({ domain, urlPath }: { domain?: string; urlPath?: string }) {
    window.localStorage.setItem(Constants.STORAGE_KEYS.domain, domain || '');
    window.localStorage.setItem(Constants.STORAGE_KEYS.urlPath, urlPath || '');
}

function findDomainByDomainName(domainName: string): Domain {
    const { primary, secondary } = getDomainsByAppEnvironment();
    return [...primary, ...secondary].find((it) => it.domain === domainName) as Domain;
}
