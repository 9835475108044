import { Constants } from './constants';
import { Logger } from './Logger';
import { LCVersions, ArtifactVersionsResponse } from './types';

export async function fetchLiveChatFrontendsVersions(domain: string): Promise<LCVersions> {
    const urlToFetchVersions = Constants.getArtifactVersionsUrl(domain);
    Logger.log(`2. Fetching versions from: ${urlToFetchVersions}`);
    try {
        const response = await fetch(urlToFetchVersions);

        if (!response.ok) {
            const errorMessage = response.body ? await response.json() : response.statusText;
            throw new Error(`⚠ Error while fetching from: ${urlToFetchVersions}: ${errorMessage}`);
        }

        const artifactsResp: Partial<ArtifactVersionsResponse> = await response.json();

        if (artifactsResp?.status !== 0) {
            throw new Error('Invalid response status');
        }

        if (!isExpectedArtifactVersionsResponse(artifactsResp)) {
            throw new Error('Invalid response format');
        }

        return mapToSimpleObject(artifactsResp);
    } catch (error) {
        Logger.error('⚠ Error while fetching versions from:', urlToFetchVersions, error);
        throw error;
    }
}

function isExpectedArtifactVersionsResponse(resp: any): resp is ArtifactVersionsResponse {
    return typeof resp?.status === 'number' && Array.isArray(resp.payload) && resp.payload.length > 0;
}

function mapToSimpleObject(artifactsResp: ArtifactVersionsResponse): LCVersions {
    const artifacts = artifactsResp.payload;
    const widget = artifacts.find((it) => it.name.includes('widget-server'));
    const components = artifacts.find((it) => it.name.includes('widget-components'));
    if (!widget || !components) {
        throw new Error('Missing artifacts');
    }
    return {
        widget: {
            links: widget.links,
            version: widget.version,
        },
        components: {
            links: components.links,
            version: components.version,
        },
    };
}

/**
 * @example response from https://api-iop1.ioinfobip.com/livechat/api/artifacts
 * curl to fetch directly from FDM-IO:
 * curl --header 'x-app-id: 1' --location 'http://infobip-fdm-artifacts-service-iop1.io-ancotel.local/artifacts/list/infobip-chat-widget-components,infobip-chat-widget-server'
 * <pre>
 * const sampleResponse: ArtifactVersionsResponse = {
 *     "status": 0,
 *     "payload": [
 *         {
 *             "name": "infobip-chat-widget-components",
 *             "links": {
 *                 "scripts": [
 *                     "https://livechat.ioinfobip.com/widget-components/__latest__/app.bundle.js"
 *                 ]
 *             },
 *             "version": "__latest__"
 *         },
 *         {
 *             "name": "infobip-chat-widget-server",
 *             "links": {
 *                 "scripts": [
 *                     "https://livechat.ioinfobip.com/widget-server/__latest__/widget.js"
 *                 ]
 *             },
 *             "version": "__latest__"
 *         }
 *     ]
 * };
 * </pre>
 */
