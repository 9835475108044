export interface Domain {
    locationId: number;
    shortName: string;
    domain: string;
    apiDomain: string;
}

export interface Domains {
    primary: Domain[];
    secondary: Domain[];
}

export const FR: Domain = {
    locationId: 2,
    shortName: 'EU',
    domain: 'livechat-fr.infobip.com',
    apiDomain: 'api.infobip.com',
};

export const FR4: Domain = {
    locationId: 10,
    shortName: 'EU',
    domain: 'livechat-fr4.infobip.com',
    apiDomain: 'api2.infobip.net',
};

export const NY2: Domain = {
    locationId: 40,
    shortName: 'NY2',
    domain: 'livechat-us.infobip.com',
    apiDomain: 'api-ny2.infobip.com',
};

export const MUNM: Domain = {
    locationId: 300,
    shortName: 'MUNM',
    domain: 'livechat-in.infobip.com',
    apiDomain: 'api-in.infobip.com',
};

export const ID1: Domain = {
    locationId: 170,
    shortName: 'ID1',
    domain: 'livechat-id.infobip.com',
    apiDomain: 'api-id1.infobip.com',
};

export const SA2: Domain = {
    locationId: 1023,
    shortName: 'SA2',
    domain: 'livechat-sa.infobip.com',
    apiDomain: 'api-sa2.infobip.com',
};

export const EU1: Domain = {
    locationId: 101,
    shortName: 'EU1',
    domain: 'livechat-eu1.infobip.com',
    apiDomain: 'api-eu1.infobip.com',
};

export const EU2: Domain = {
    locationId: 102,
    shortName: 'EU2',
    domain: 'livechat-eu.infobip.com',
    apiDomain: 'api-eu2.infobip.com',
};

export const TR1: Domain = {
    locationId: 240,
    shortName: 'TR1',
    domain: 'livechat-tr.infobip.com',
    apiDomain: 'api-tr1.infobip.com',
};

export const GB1: Domain = {
    locationId: 250,
    shortName: 'GB1',
    domain: 'livechat-gb.infobip.com',
    apiDomain: 'api-gb1.infobip.com',
};

export const AE2: Domain = {
    locationId: 260,
    shortName: 'AE2',
    domain: 'livechat-ae.infobip.com',
    apiDomain: 'api-ae2.infobip.com',
};

export const PK2: Domain = {
    locationId: 280,
    shortName: 'PK2',
    domain: 'livechat-pk.infobip.com',
    apiDomain: 'api-pk2.infobip.com',
};

export const QA1: Domain = {
    locationId: 320,
    shortName: 'QA1',
    domain: 'livechat-qa.infobip.com',
    apiDomain: 'api-qa1.infobip.com',
};

export const RU: Domain = {
    locationId: -1,
    shortName: 'RU',
    domain: 'livechat-ru.infobip.com',
    apiDomain: 'api-ru.infobip.com',
};

export const IO: Domain = {
    locationId: 2,
    shortName: 'IOP1',
    domain: 'livechat-iop1.ioinfobip.com',
    apiDomain: 'api-iop1.ioinfobip.com',
};
