/* eslint-disable no-console */

/**
 * Logging is disabled by default. To enable them, call `localStorage['LC:logging'] = true`.
 */
export class Logger {
    private static isLoggingEnabled = () => window.localStorage.getItem('LC:logging') === 'true';
    private static isWebView = /android|; wv|iphone|ipod|ipad/.test(navigator.userAgent.toLocaleLowerCase('en-US'));

    static log(msg?: any, ...optionalParams: any[]) {
        if (this.isLoggingEnabled()) {
            optionalParams = this.isWebView
                ? optionalParams.map((p) => (p && typeof p === 'object' ? JSON.stringify(p) : p))
                : optionalParams;
            console.log(`LC-loader » ${msg}`, ...optionalParams);
        }
    }

    static error(msg?: any, ...optionalParams: any[]) {
        if (this.isLoggingEnabled()) {
            console.error(`LC-loader » ${msg}`, ...optionalParams);
        }
    }
}
