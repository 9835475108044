import { Logger } from './Logger';

/**
 * this is a type safe version of the modified (LiveChat) snippet script for loading JS in the browser
 * ```
 * (function(I,n,f,o,b,i,p){
 * I[b]=I[b]||function(){(I[b].q=I[b].q||[]).push(arguments)};
 * I[b].t=1*new Date();i=n.createElement(f);i.async=1;i.src=o;
 * p=n.getElementsByTagName(f)[0];p.parentNode.insertBefore(i,p)})
 * (window,document,'script','https://livechat.infobip.com/widget.js','liveChat');
 * ```
 * source:
 * • https://git.ib-ci.com/projects/MAR/repos/infobip-apps-livechat/browse/src/pages/widgets/widget-edit/utils/index.ts#20-29
 * • https://git.ib-ci.com/projects/CCAAS/repos/infobip-conversation-web/browse/src/pages/my-work/mywork-livechat-widget.js
 * • https://git.ib-ci.com/projects/LC/repos/infobip-chat-widget-server/browse/src/assets/test.html#91-104
 * @param scriptUrl
 */
export function createBrowserScript(scriptUrl: string): Promise<ErrorEvent | null> {
    Logger.log('3. js load started, url:', scriptUrl);
    return new Promise<ErrorEvent | null>((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = scriptUrl;
        document.head.insertAdjacentElement('afterbegin', script);

        script.addEventListener('load', () => {
            Logger.log('3.✔ js loaded successfully:', script.src);
            resolve(null);
        });

        script.addEventListener('error', (error) => {
            document.head.removeChild(script);
            Logger.log('3.⚠ js load error for script', { url: script.src, error });
            reject(error);
        });
    }).catch((error) => {
        Logger.error(`3. js load failed, url (${scriptUrl}):`, error);
        throw error;
    });
}
